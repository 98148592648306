import withGlobalAppLayout from '../modules/ui/containers/HOC/withGlobalAppLayout';
import NotFound from '../modules/ui/pages/NotFound';

export default function index(props: any)
{
    return withGlobalAppLayout(NotFound, {
        ...props,
        locale: props.pageContext.locale,
        setDrawerStatus: () => false,
        setUser: () => false,
        appDrawerIsOpen: false,
        pathname: '',
        hideGfLogo: false,
    });
}

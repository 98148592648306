import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';

import PageHeader from '../../atoms/PageHeader';

const NotFound = () =>
{
    return (
        <section className="not-found-container">
            <PageHeader
                title="not_found"
                translateTitle={true}
            />
            <img className="not-found-image" src="/badlink.png" alt="Broken Link"/>
            <div className="not-found-text">
                <FormattedMessage
                    id="not_found_text"
                    values={{
                        hereEn: <p><Link to="/">here</Link>.</p>,
                        hereDe: <p><Link to="/">hier</Link> klicken.</p>,
                    }}
                />
            </div>
        </section>
    );
};

export default NotFound;
